import Player from '@vimeo/player';
import Swiper, { Navigation, EffectFade } from 'swiper';

// import Swiper and modules styles
// import 'swiper/css';
// import 'swiper/css/navigation';

const _ = require('lodash');
_.noConflict();

/**
 * Vimeo Slider
 **/
class VimeoSlider {
	constructor() {
		//console.log('VimeoSlider:constructor');

		const _this = this;
		window.addEventListener('anlDocReady', function (e) {
			_this.onReady(e);
		});
	}

	onReady(event) {
		//console.log('VimeoSlider::onReady', this, event);

		window.addEventListener('resize', _.debounce(this.onResize.bind(this), 20));
		document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));

		//this.createLoadingScreens();

		// setup swiper
		this.swiper = new Swiper('.vs.swiper', {
			modules: [Navigation, EffectFade],
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			speed: 400,
			loop: true,
			//keyboard: true,
			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		this.swiper.on("transitionStart", this.onTransitionStart.bind(this));
		this.swiper.on("transitionEnd", this.onTransitionEnd.bind(this));
		//this.swiper.on("slideChange", () => { console.log('SLIDE CHNAGED') });

		this.onTransitionEnd(this.swiper);
		this.onTransitionStart(this.swiper);

		setTimeout(()=> {
			const nav = document.querySelector('.vs__navigation');
			if(nav) {
				nav.classList.add('active');
			}
		}, 3500);
	}

	/**
	 * Create Loading Screens
	 */
	createLoadingScreens() {
		const slides = document.querySelectorAll('.vs__slide-item');

		slides.forEach(slide => {
			// Create our loading screen div
			var loadingScreen = document.createElement('div');
			loadingScreen.classList.add('vs__slide-loading');

			// append the loading screen div to our slide div
			slide.appendChild(loadingScreen);
		});
	}

	setupSlides() {
		//console.log('VimeoSlider::setupSlides');
		var numSlides = this.swiper.slides.length - 2,
			realIndex = this.swiper.realIndex,
			currentSlides = [],
			_this = this;

		currentSlides.push(realIndex === numSlides ? 0 : realIndex + 1);
		currentSlides.push(realIndex === 0 ? numSlides - 1 : realIndex - 1);
		currentSlides.push(realIndex);

		const slides = document.querySelectorAll('.vs__slide');

		for (var i = 0; i < slides.length; ++i) {
			var slide = slides[i],
				slideIndex = Number(slide.dataset.swiperSlideIndex);

			//console.log('\tslide', slide);
			//console.log('\tslideIndex', slideIndex);

			if (currentSlides.indexOf(slideIndex) !== -1) {
				this.setupSlide(slide);
			}
		}
	}

	setupSlide(slide) {

		this.setupVideo(slide);
		//this.setupImage(slideItem);
	}

	setupVideo(slide) {
		const slideItem = slide.querySelector('.vs__slide-item');

		if (!slideItem.classList.contains('video-loading') && !slideItem.classList.contains('video-loaded')) {
			// add loading class
			slideItem.classList.add('video-loading');

			// create and append our video player div
			const videoDiv = document.createElement('div');
			videoDiv.classList.add('vs__slide-video');

			slideItem.querySelector('.inner').appendChild(videoDiv);

			// setup vimeo player
			// const vimeoOptions = {
			// 		id: slideItem.dataset.vimeo_id,
			// 		width: 1080,
			// 		background: true,
			// 		responsive: true,
			// 		loop: false,
			// 		// autoplay: false,
			// 		// autopause: true,
			// 		// muted: true,
			// 		// controls: false,
			// 		// title: false,
			// 		// byline: false,
			// 		// portrait: false,
			// 	},
			// 	_this = this;

			const vimeoOptions = {
				id: slideItem.dataset.vimeo_id,
				width: 1920,
				// background: true,
				loop: false,
				autoplay: false,
				muted: true,
				controls: false,
				title: false,
				byline: false,
				portrait: false,
				// transparent: true,
			},
				_this = this;

			this.vimeoOptions = vimeoOptions;

			const player = new Player(videoDiv, this.vimeoOptions);

			player.on('loaded', (e) => {
				_this.onVideoLoaded(e, slide, slideItem);
			});

			player.on('ended', (e) => {
				_this.onVideoEnded(e, slide, player);
			});
		}
	}

	setupImage(slideItem) {
	}

	onVideoLoaded(e, slide, slideItem, player) {
		const slideIndex = Number(slide.dataset.swiperSlideIndex);
		//console.log('VimeoSlider::onVideoLoaded slideIndex:' + slideIndex);
		slideItem.classList.add('video-loaded');
		slideItem.classList.remove('video-loading');

		if (slide === this.getCurrentSlide()) {
			//console.log('Current video has been loaded, telling it to play!');
			this.playSlide(slide);
		} else {
			//console.log('video with ID of ' + slideIndex + ' is not current, will not play!');
		}

		// remove the loaded listener
		player.off('loaded');
	}

	onVideoEnded(e, slide, player) {
		const slideIndex = Number(slide.dataset.swiperSlideIndex);
		//console.log('VimeoSlider::onVideoEnded slideIndex:' + slideIndex);
		// if(player) {
		// 	player.pause().then(function() {
		// 		// The video is paused
		// 	}).catch(function(error) {
		// 		console.log('ERROR:', error.name);
		// 	});
		// 	player.setCurrentTime(player.getDuration());
		// } else {
		// 	console.log('ERROR: NO PLAYER');
		// }

		if (slide === this.getCurrentSlide()) {
			//console.log('\tEnded video is the current video, changing the slide now!');
			this.swiper.slideNext();
		} else {
			//console.log('\tEnded video is NOT the current video, doing nothing!');
		}

		// remove the ended listener
		player.off('ended');

	}

	onResize() {
	}

	onVisibilityChange() {
		if (document.visibilityState === 'visible') {
			var currentSlide = this.getCurrentSlide();
			this.playSlide(currentSlide);
		}
	}

	getCurrentSlide() {
		//console.log('VimeoSlider::getCurrentSlide');
		return document.querySelector('.vs .swiper-slide-active');
		//return this.swiper.slides[this.swiper.realIndex];
	}

	getCurrentSlideItem() {
		return this.getCurrentSlide().querySelector('.vs__slide-item');
	}

	playSlide(slide) {
		if (slide && slide === this.getCurrentSlide()) {

			const video = slide.querySelector('iframe');

			var slideIndex = Number(slide.dataset.swiperSlideIndex);
			//console.log('VimeoSlider::playSlide slideIndex:' + slideIndex);

			if (slide.classList.contains('video-loading')) {
				//console.log('\tERROR: Video not loaded yet!');
			}

			//console.log('\tslide', slide);
			//console.log('\tvideo', video);

			if (video) {
				//console.log('\t We have a vieo!');
				const player = new Player(video);
				//console.log(player);
				player.play().then(function () {
					// The video is playing
					//console.log('VimeoSlider::playSlide slideIndex:' + slideIndex + ' ALERT: video is playing!');

					// player.on('ended', (e) => {
					// 	_this.onVideoEnded(e, slide, player);
					// });

					// player.setCurrentTime(0).then(function(seconds) {
					// 	// `seconds` indicates the actual time that the player seeks to
					// 	console.log('\tSeconds: ' + seconds);
					// }).catch(function(error) {
					// 	console.log('\tERROR: Error setting player current time!', error.name);
					// });
				}).catch(function (error) {
					//console.log('\tERROR: ' + error.name);
				});
			} else {
				//console.log('VimeoSlider::playSlide slideIndex:' + slideIndex + ' ERROR: No video found!');
			}
		} else {
			//console.log('\tERROR: NOT CURRENT VIDEO');
		}
	}

	pauseSlide(slide) {
		var slideIndex = Number(slide.dataset.swiperSlideIndex);
		//console.log('VimeoSlider::pauseSlide slideIndex:' + slideIndex);

		const video = slide.querySelector('iframe');
		//console.log('\tvideo', video);

		if (video) {
			const player = new Player(video);

			if (!player.getPaused()) {
				player.pause().then(function () {
					// The video is paused
					//console.log('VimeoSlider::pauseSlide slideIndex:' + slideIndex + ' ALERT: video is paused!');
					player.setCurrentTime(0);
				}).catch(function (error) {
					//console.log('VimeoSlider::pauseSlide slideIndex:' + slideIndex + '\tERROR: ' + error.name);
				});
			}
		} else {
			//console.log('VimeoSlider::pauseSlide slideIndex:' + slideIndex + ' ERROR: No video found!');
		}
	}

	onTransitionStart(swiper) {
		//console.log('VimeoSlider::onTransitionStart', swiper.realIndex);

		const currentSlide = this.getCurrentSlide();

		// tell the currrent slide to play
		//setTimeout(() => {
		this.playSlide(currentSlide);
		//}, 1000);
	}

	destroyLastSlide(swiper) {

		const slide = swiper.slides[swiper.previousIndex];
		const slideItem = slide.querySelector('.vs__slide-item');
		const videoDiv = slide.querySelector('.vs__slide-video');
		const video = slide.querySelector('iframe');

		if (slideItem) {

			slideItem.classList.remove('video-loading', 'video-loaded');

			if (videoDiv) {

				if (video) {
					const player = new Player(video);
					player.destroy();
				}

				videoDiv.remove();
			}
		}
	}

	onTransitionEnd(swiper) {
		//console.log('VimeoSlider::onTransitionEnd', swiper.realIndex, swiper.previousIndex, swiper.slides);

		if (typeof swiper.previousIndex !== 'undefined') {
			this.destroyLastSlide(swiper);
		}

		this.setupSlides();

		// pause and reset all videos except for the current slides
		const slides = document.querySelectorAll('.vs__slide');
		const currentSlide = this.getCurrentSlide();

		slides.forEach((slide) => {
			if (slide !== currentSlide) {
				this.pauseSlide(slide);
			}
		});
	}
}

const vimeoSlider = window.vimeoSlider || new VimeoSlider();
window.vimeoSlider = vimeoSlider;

export default vimeoSlider;
